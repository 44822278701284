import { useEffect } from 'react';
import { useConfig, useToken, useUserSettings, useCurrentUser, useCurrentScope } from '@customer-connect/hooks-web';
import ClientWrapper from '@ccsdk/core/push/clientWrapper';

if (!window.ccsdkGlobals) {
  window.ccsdkGlobals = {};
}

if (!window.ccsdkGlobals.wnsClients) {
  window.ccsdkGlobals.wnsClients = {};
}

const LegacyWebsocketProvider = () => {
  const maxReconnectAttempts = useConfig('websocketReconnectAttempts');
  const accessToken = useToken();
  const { locale, measurementSystem } = useUserSettings();
  const { platformUserIdentifier } = useCurrentUser();
  const { id } = useCurrentScope();
  const wnsGateway = useConfig('gateways.wnsGateway');

  useEffect(() => {
    const endpoint = wnsGateway;
    if (accessToken && locale && measurementSystem && platformUserIdentifier && id && maxReconnectAttempts && wnsGateway) {
      try {
        if (!window.ccsdkGlobals.wnsClients[endpoint]) {
          console.debug('App Shell', 'WS', 'Initiating legacy websocket client');
          window.ccsdkGlobals.wnsClients[endpoint] = new ClientWrapper({
            userId: platformUserIdentifier,
            label: id,
            endpoint,
            accessToken,
            locale,
            measurementSystem,
            maxReconnectAttempts,
          });
          window.ccsdkGlobals.wnsClients[endpoint].activate();
        }
      } catch (e) {
        console.error('App Shell', 'Error while creating websocket client', e);
      }
    } else {
      console.debug('App Shell', 'WS', 'Waiting on init', accessToken, locale, measurementSystem, platformUserIdentifier, id, maxReconnectAttempts, wnsGateway);
    }
  }, [accessToken, locale, measurementSystem, platformUserIdentifier, id, maxReconnectAttempts, wnsGateway]);

  return null;
};

export default LegacyWebsocketProvider;
