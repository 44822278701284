import { memo } from 'react';
import { node } from 'prop-types';
import { useConfig } from '@customer-connect/hooks-web';
import { Portal, PortalBody, PortalContent } from '@ccsdk/kingbolt/_internal';
import GlobalNotifications from '~/components/GlobalNotifications';
import Header from '~/components/Layout/Header';
import useInitialSetup from '~/hooks/useInitialSetup';
import { withProfiler } from '@sentry/react';

const Layout = ({ children }) => {
  const noHeader = useConfig('header.disable');
  const disableHeader = useConfig('noHeader');
  const region = useConfig('region');
  const showInitialSetup = useInitialSetup();

  return (
    <Portal>
      {region !== 'cn' && <GlobalNotifications />}
      {!showInitialSetup && !noHeader && !disableHeader && <Header />}

      <PortalBody data-test-main>
        <PortalContent>{children}</PortalContent>
        <div id="portal-drawer-container" />
      </PortalBody>
    </Portal>
  );
};

Layout.propTypes = {
  children: node,
};

export default memo(withProfiler(Layout, { name: 'Layout' }));
