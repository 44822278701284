import { put, take, call, select } from 'redux-saga/effects';
import { makeSelectConfig } from '@customer-connect/redux-selectors';
import { makeSelectQuery } from '@customer-connect/redux-selectors';

import { loadDynamicModulesOfType, DYNAMIC_METADATA_LOADED } from '@ccsdk/core/ducks/dynamicModules';
import { isLoginMode } from '@customer-connect/utils';

export const isTestbotSelector = makeSelectConfig('isTestBot');
export const isAppModeSelector = makeSelectQuery('app');

function* loadDynamicHeaderNodes() {
  yield take(DYNAMIC_METADATA_LOADED);

  const loginMode = yield call(isLoginMode);
  const isTestbot = yield select(isTestbotSelector);
  const isAppMode = yield select(isAppModeSelector);

  if (loginMode) {
    if (isTestbot || !!isAppMode || !!window.Cypress) {
      console.debug('App Shell', 'Login and Testbot mode detected, prefetching nothing');
    } else {
      console.debug('App Shell', 'Header', 'Login mode detected, prefetching header nodes');
      yield put(loadDynamicModulesOfType('headerNode', { prefetch: true }));
    }
  } else {
    console.debug('App Shell', 'Header', 'loading dynamic header modules');
    yield put(loadDynamicModulesOfType('headerNode'));
  }
}

export default loadDynamicHeaderNodes;
