/* eslint-disable no-param-reassign */

import { useEffect } from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { init, replayIntegration, setTag, withProfiler, reactRouterV6BrowserTracingIntegration, metrics } from '@sentry/react';
import {
  getClient,
  captureException,
  captureMessage,
  withScope,
  addBreadcrumb,
  reportingObserverIntegration,
  extraErrorDataIntegration,
  httpClientIntegration,
  captureConsoleIntegration,
} from '@sentry/browser';
import {} from '@sentry/integrations';

import { getRoot } from '@customer-connect/utils';
const { v4 } = require('uuid');

if (!window.ccsdkGlobals.sentry) {
  window.ccsdkGlobals.sentry = {
    getClient: () => null,
    captureException: () => {},
    captureMessage: () => {},
    withScope: () => {},
    addBreadcrumb: () => {},
    withProfiler: (component) => component,
    sentryInitiated: false,
    metrics: () => {},
    browserConsole: {
      info: console.info,
      warn: console.warn,
      error: console.error,
      debug: console.debug,
    },
  };
}

const resolveDSN = ({ brand, region }) => {
  if (region === 'na') {
    if (brand === 'volvoconnect') {
      return 'https://4458cf37320514ce28fc7d4aeebb1043@stablegw.prod.shared.eu.vgtng.volvo.com/18';
    }
    if (brand === 'macktrucksconnect') {
      return 'https://0c6d73b389e59ac6802aa4d49d12a413@stablegw.prod.shared.eu.vgtng.volvo.com/19';
    }
    if (brand === 'snac') {
      return 'https://47d1e21e0561ccd392b7044227e0c605@stablegw.prod.shared.eu.vgtng.volvo.com/22';
    }
  } else if (region === 'eu') {
    if (brand === 'snac') {
      return 'https://5b6c744b104c1c5ac258385a77db05f8@stablegw.prod.shared.eu.vgtng.volvo.com/21';
    }
    if (brand === 'volvoconnect') {
      return 'https://aef5a9126264a82b012a6033eca0da47@stablegw.prod.shared.eu.vgtng.volvo.com/15';
    }
    if (brand === 'volvobusconnect') {
      return 'https://af3fef6f28fa47758fa5098fe30a9041@stablegw.prod.shared.eu.vgtng.volvo.com/20';
    }
    if (brand === 'optifleet') {
      return 'https://f9c42b67a93378ad4b502dd8ba5c2824@stablegw.prod.shared.eu.vgtng.volvo.com/16';
    }
    if (brand === 'elegantconnect') {
      return 'https://c57b050355ee940d00466f263a8963f5@stablegw.prod.shared.eu.vgtng.volvo.com/17';
    } else if (region === 'cn') {
      if (brand === 'volvoconnect') {
        return 'https://3b1c263b240b3e3c7a20758d759f4ccc@stablegw.prod.shared.eu.vgtng.volvo.com/29';
      }
    }
  }
  return 'https://4458cf37320514ce28fc7d4aeebb1043@stablegw.prod.shared.eu.vgtng.volvo.com/2';
};

export const getAppShellVersion = () => window?.ccsdkGlobals?.appShell?.version;
// export const getUserID = () => window.ccsdkGlobals.adobe.adobeUID;
export const getFleetID = () => window?.ccsdkGlobals?.adobe?.fleetUID;
export const getCompanyId = () => window?.ccsdkGlobals?.adobe?.companyUID;
export const getReplayId = () => getClient()?.getIntegrationByName('Replay')?.getReplayId?.();

// Regexps
const adobeRegex = /(http|https):\/\/[\w.]*?(adobedtm\.com|assetsadobe\.com|volvogroup\.data\.adobedc\.net|demdex\.net|everesttech\.net|adobedc\.net)[\w./]*/;
const walkmeRegex = /(http|https):\/\/[\w.]*?(walkme\.com|s3\.walkmeusercontent\.com|s3\.euto\.walkmeusercontent\.com|eu-cdn\.walkme\.com)[\w./]*/;
const staticFileRegex = /^.*\.(js|jpg|jpeg|png|gif|svg|woff|ttf|otf|eot|css)$/i;
const apiURLRegex = /^https:\/\/api.*/i;
// const nonAPIURLRegex = /^(?!https:\/\/api).*/i;
// const localhostRegexp = /http:\/\/(localhost|127\.0\.0\.1)(:\d+)?(\/[^\s]*)?/g;
// const idpURLRegex = /(?:http[s]?:\/\/)?[\w.,@?^=%&:\/~+#-]*\/(identity|identityprovider)[\w.,@?^=%&:\/~+#-]*/i;
// eslint-disable-next-line no-useless-escape

const isExternalUrl = (url = '') => url.match(walkmeRegex) || url.match(adobeRegex);

const isAPIUrl = (url = '') => url.match(apiURLRegex);

const isStaticFileUrl = (url = '') => url.match(staticFileRegex);

const clone = (item) => JSON.parse(JSON.stringify(item));

const ALLOWED_HEADERS = ['x-vgt-trackingid', 'x-vgt-clientid', 'vgt-clientid', 'vgt-trackingid'];

const getIdentifier = () => v4().replace(/-/g, '').toLowerCase();

const getOrCreateSessionId = () => {
  try {
    let id = window.sessionStorage.getItem('portalSentrySession');
    if (!id) {
      id = getIdentifier();
      window.sessionStorage.setItem('portalSentrySession', id);
    }
    return id;
  } catch (err) {
    return 'unknown_session';
  }
};

const hasServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registrations = await navigator.serviceWorker.getRegistrations();
      return registrations.length > 0;
    } catch (err) {
      return false;
    }
  } else {
    return false;
  }
};

const initSentry = async ({ environment, brand, region, isTestBot }) => {
  const isProd = environment === 'prod';
  const isLocal = environment === 'local';
  const isCI = environment === 'local' && isTestBot;
  const isChina = region === 'cn';
  const serviceWorkerInstalled = await hasServiceWorker();

  if (isChina) {
    console.warn('App Shell', 'Sentry', 'China region detected, disabling Sentry');
    return;
  }

  const dsn = resolveDSN({ brand, region });
  if (!dsn) {
    console.warn('App Shell', 'Sentry', 'No Sentry config available for brand', brand, 'in region', region);
  }

  init({
    dsn,
    environment,
    beforeSend: (event) => {
      event.extra = { ...(event.extra || {}), tool: getRoot(), isTestBot, isCI };
      event.tags = {
        ...(event.tags || {}),
        module: 'app-shell',
        tool: getRoot(),
        isTestBot,
        isCI,
        region,
        fleetId: getFleetID(),
        companyId: getCompanyId(),
        ccReplayId: getReplayId(),
        appShell: getAppShellVersion(),
        serviceWorkerInstalled,
        portalSessionId: getOrCreateSessionId(),
      };
      return event;
    },
    beforeBreadcrumb(breadcrumb, hint) {
      try {
        // All request crumbs will have an URL
        const url = breadcrumb?.data?.url;

        if (isAPIUrl(url) && !isExternalUrl(url)) {
          let vgtTrackingIdHeader = 'unknown';

          // Handle XHR requests
          if (typeof hint?.xhr?.getResponseHeader === 'function') {
            // eslint-disable-next-line no-underscore-dangle
            const header = hint.xhr.getResponseHeader('Vgt-Trackingid') || hint.xhr.getResponseHeader('vgt-trackingid');
            if (header) {
              vgtTrackingIdHeader = header;
            }
          }

          // Handle regular fetches
          if (typeof hint?.response?.headers?.entries === 'function') {
            for (const pair of hint.response.headers.entries()) {
              if (pair[0]?.toLowerCase() === 'vgt-trackingid') {
                vgtTrackingIdHeader = pair[1];
              }
            }
          }

          if (vgtTrackingIdHeader && breadcrumb?.data) {
            breadcrumb.data.tracking_id = vgtTrackingIdHeader;
          }
        }
      } catch (error) {
        console.warn('App Shell', 'Sentry', 'Error when editing breadcrumb', error);
      }

      return breadcrumb;
    },
    maxBreadcrumbs: 1000,
    sendClientReports: true,
    attachStacktrace: true,
    autoSessionTracking: true,
    maxValueLength: 1000,
    normalizeDepth: 10,
    beforeSendTransaction(event) {
      const { transaction = 'nothing' } = event || {};
      if (transaction.includes('https://api' || transaction.includes('edge.adobedc.net') || transaction.includes('walkme.com'))) {
        return null;
      }
      return event;
    },
    integrations: [
      captureConsoleIntegration({
        levels: ['error'],
      }),
      reactRouterV6BrowserTracingIntegration({
        useEffect: useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      httpClientIntegration({
        failedRequestStatusCodes: [
          [300, 399],
          [400, 499],
          [500, 599],
        ],
      }),
      extraErrorDataIntegration({
        depth: 5,
      }),
      reportingObserverIntegration(),
      replayIntegration({
        maskAllText: !isTestBot,
        useCompression: true,
        blockAllMedia: false,
        minReplayDuration: 5000,
        // eslint-disable-next-line
        networkDetailAllowUrls: [apiURLRegex],
        networkResponseHeaders: clone(ALLOWED_HEADERS),
        beforeAddRecordingEvent: (event) => {
          try {
            // Drop static files
            const url = event?.data?.payload?.description || 'nodesc';
            if (isStaticFileUrl(url) || isExternalUrl(url)) {
              // console.log('App Shell', 'Sentry', 'Dropping Replay event', event);
              return null;
            }
            // Default is just to pass the event through
            return event;
          } catch (err) {
            // console.warn('App Shell', 'Sentry', 'Error when filtering event, returning it by default', err, event);
            return event;
          }
        },
      }),
    ].filter((item) => !!item),
    tracePropagationTargets: ['tracenothing'],
    tracesSampleRate: isLocal || isTestBot ? 0.0 : 0.25,
    replaysSessionSampleRate: isTestBot ? 1.0 : 0.0,
    replaysOnErrorSampleRate: isProd ? 1.0 : 0.0,
  });

  setTag('brand', brand);

  const flushReplays = () => {
    try {
      const replay = getClient().getIntegrationByName('Replay');
      // eslint-disable-next-line no-underscore-dangle
      const recordingMode = replay?._replay?.recordingMode || 'buffer';
      if (recordingMode === 'session') {
        replay.flush();
      }
    } catch (err) {
      console.warn('App Shell', 'Sentry', 'Failed to offload Sentry Replay', err);
    }
  };

  // Manually flush replays every 15 seconds
  setInterval(() => {
    flushReplays();
  }, 15 * 1000);

  window.addEventListener('beforeunload', () => {
    flushReplays();
  });

  window.ccsdkGlobals.sentry = {
    getClient: () => {
      const client = getClient();
      return {
        ...client,
        getIntegrationById: client.getIntegrationByName,
      };
    },
    captureException: (err) => captureException(err),
    captureMessage: (mess) => captureMessage(mess),
    withScope: (cb) => withScope(cb),
    withProfiler: (component) => withProfiler(component),
    addBreadcrumb: (crumb) => addBreadcrumb(crumb),
    sentryInitiated: true,
    metrics,
    browserConsole: {
      info: console.info,
      warn: console.warn,
      error: console.error,
      debug: console.debug,
    },
  };
};

export { initSentry };
