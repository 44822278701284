/* eslint-disable camelcase,no-nested-ternary,prefer-rest-params */
import config from '@ccsdk/core/config';
import setupConfig from '@ccsdk/core/config/setupConfig';
import { loadFederatedModuleEntryPoint, importFederatedModuleRoot } from '@ccsdk/core/ducks/dynamicModules';
import { isLoginMode, isLocalhost, isMarketPlaceMode } from '@customer-connect/utils';
import deepmerge from 'deepmerge';
import { startApp } from '~/components/Main';
import { resolveTheme, resolveThemeMode } from '~/components/Boot/utils';
import { ccauthInit } from './ccauth';
import { initSentry } from './sentry';
import { initCypress } from './cypress';
import { isPublicSection } from './public';
import { initServiceWorker } from './serviceWorker';
import version from '../../../vgtCompVersion';
import queries from './queries';
import './adobe';

if (!window.ccsdkGlobals) {
  window.ccsdkGlobals = {};
}

window.ccsdkGlobals.appShell = {
  version,
};

// Manual Google Analytics override, remove in SDK 15
if (!window.ccsdkGlobals.googleAnalytics) {
  window.ccsdkGlobals.googleAnalytics = {
    isInitiated: true,
  };
}

export const run = async () => {
  console.debug('App Shell', 'Boot', 'Booting app');

  const isEmergencyMode = !!queries.emergencyMode;
  const isTestBot = !!queries.isTestBot || !!window.Cypress;
  const localhost = isLocalhost();

  console.debug('App Shell', 'Boot', 'Loading remote config');
  // If we are running the new system the remote config should already be loaded
  let remoteConfig = window.ccsdkGlobals.configuration;

  // If not fetch the old way
  if (!remoteConfig) {
    const configResponse = await fetch('/framework/config.json');
    remoteConfig = await configResponse.json();

    if (remoteConfig.flavour === 'optifleet-new') {
      remoteConfig.flavour = 'optifleet';
    }

    if (remoteConfig.environment?.includes('optifleet-new')) {
      remoteConfig.environment = remoteConfig.environment.replace('optifleet-new', 'optifleet');
    }

    if (remoteConfig.liveDomain) {
      const liveDomainResponse = await fetch(`https://${remoteConfig.liveDomain}/config.json`);
      const liveDomainConfig = await liveDomainResponse.json();

      const { baseApiDomain, targetApiDomain } = remoteConfig;

      remoteConfig = JSON.parse(JSON.stringify(deepmerge(remoteConfig, liveDomainConfig)).replace(new RegExp(baseApiDomain, 'g'), targetApiDomain));
    }
  }

  const environment = remoteConfig.env || remoteConfig.environment || remoteConfig.deployEnv;

  // Disable trivial logs in prod
  if (environment === 'prod') {
    console.log = () => {};
    console.debug = () => {};
  }

  // Setup sentry
  console.info('App Shell', 'Boot', 'Initiating Sentry');
  await initSentry({ isTestBot, environment: remoteConfig.env || remoteConfig.environment, brand: remoteConfig.brand, region: remoteConfig.region });

  if (window.Cypress) {
    await initCypress();
  }

  // Setup ccauth
  console.info('App Shell', 'Boot', 'Initiating ccauth');
  await ccauthInit();

  if (navigator.serviceWorker && !isTestBot && !localhost) {
    initServiceWorker();
  } else {
    console.debug('Service Worker', 'Service Worker is disabled for testbot and localhost');
  }

  console.debug('App Shell', 'Boot', 'Preinjecting translations');
  await import('~/i18n');

  const [dynamicModulesResponse, cloudfrontMetaResponse] = await Promise.all([fetch('/dynamicModules.json'), fetch('/cloudfrontMeta.json')]);
  const dynamicModules = await dynamicModulesResponse.json();
  const cloudfrontMeta = await cloudfrontMetaResponse.json();

  console.debug('App Shell', 'Boot', 'Fetched remote config', remoteConfig);

  // Setup the config object
  setupConfig([], remoteConfig, queries);

  const brand = config.get('brand');

  // Set whether to show logs or not
  const disableLogs = !!queries.disableLogs || localStorage.getItem('devToolsLogsDisabled') || localhost || false;
  if (disableLogs) {
    console.info('App Shell', 'Boot', 'Disabling logs');
  } else {
    localStorage.removeItem('disableLogs');
  }
  config.set('disableLogs', disableLogs);

  // If we have a valid auth cycle, disable no-auth (e.g. if the user has logged in)
  const noAuth = isLoginMode() || isPublicSection(environment) || isEmergencyMode || !!queries?.noAuth || false;
  if (noAuth) {
    console.debug('App Shell', 'Boot', 'Enabling noAuth mode');
  } else {
    console.debug('App Shell', 'Boot', 'Disabling noAuth mode');
  }
  config.set('noAuth', noAuth);

  const reduxDevTools = !!queries.reduxDevTools || remoteConfig.reduxDevTools || config.get('reduxDevTools') || localhost || false;
  if (reduxDevTools) {
    console.info('Core', 'Boot', 'Enabling reduxDevTools mode');
  }

  config.set('isTestBot', isTestBot);
  config.set('emergencyMode', isEmergencyMode);
  config.set('portal', 'customer');
  config.set('reduxDevTools', reduxDevTools);
  config.set('federatedToolsEnabled', true);
  config.set('standalone', false);
  config.set('marketplaceContext', false);
  config.set('disableGA', true);

  // Enable/Disable header
  const noHeader = !!queries.noHeader || false;
  if (noHeader) {
    console.debug('App Shell', 'Boot', 'Enabling noHeader mode');
  }
  config.set(['header', 'disable'], noHeader);
  config.set('noHeader', noHeader);

  // Login specific settings
  if (isLoginMode()) {
    console.debug('App Shell', 'Boot', 'Login mode enabled, disabling header');
    config.set(['header', 'disable'], true);
    config.set('noHeader', true);
    config.set('isLoginApp', true);
    config.set('forceWalkMe', true);
  }

  if (isMarketPlaceMode()) {
    config.set('portal', 'marketplace');
    config.set('marketplaceContext', true);
  }

  const themeName = resolveTheme(config.get('theme'));
  const themeMode = resolveThemeMode();
  console.debug('App Shell', 'Boot', 'Loading dynamic theme', brand, themeName, `Mode: ${themeMode}`);

  const loadTheme = async () => {
    await loadFederatedModuleEntryPoint({ moduleURL: `/themes/${themeName}/ccFederatedModuleDefinition.js`, key: `theme-${themeName}` });
    const [themeRoot] = await Promise.all([importFederatedModuleRoot('ccTheme', brand, true)]);

    if (themeMode === 'dark') {
      return themeRoot.darkTheme || themeRoot.default;
    }

    return themeRoot.default;
  };

  const theme = await loadTheme();

  return startApp({ theme, queries, config, dynamicModules, cloudfrontMeta });
};
