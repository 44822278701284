import delay from '@redux-saga/delay-p';
import { call, put, select } from 'redux-saga/effects';
import { lastTokenRefresh } from '@customer-connect/redux-actions';
import { LAST_TOKEN_REFRESH } from '@customer-connect/redux-constants';
import { selectExpireTime } from '@customer-connect/redux-selectors';

import { makeSelectConfig } from '@customer-connect/redux-selectors';
import { getStore } from '@ccsdk/core/redux-store';

export const accessTokenConfigSelector = makeSelectConfig('accessToken');

export const calcTokenRefreshDelay = (originalExpireTime, refreshInterval, refreshTimeBeforeExpired) => {
  const actualExpireTime = originalExpireTime - Date.now();

  // console.debug('App Shell', 'Auth', 'Token Refresh', 'Original expire time', originalExpireTime);
  // console.debug('App Shell', 'Auth', 'Token Refresh', 'Actual expire time', actualExpireTime);
  // console.debug('App Shell', 'Auth', 'Token Refresh', 'Refresh interval', refreshInterval);
  // console.debug('App Shell', 'Auth', 'Token Refresh', 'Refresh time before expiry', refreshTimeBeforeExpired);

  // console.debug('App Shell', 'Auth', 'Token Refresh', 'Setting delay for token refresh', remainingTime);

  return Math.max(0, actualExpireTime - refreshTimeBeforeExpired);
};

export function* setupNextTokenRefresh(timestamp) {
  const { refreshInterval, refreshTimeBeforeExpired } = yield select(accessTokenConfigSelector);
  const expireTime = yield select(selectExpireTime);
  yield call(delay, calcTokenRefreshDelay(expireTime, refreshInterval, refreshTimeBeforeExpired) + Math.floor(Math.random() * 40));
  yield put(lastTokenRefresh(timestamp));
}

/* istanbul ignore next */
export function* delegateNextTokenRefresh() {
  const timestamp = new Date().toISOString();
  yield setupNextTokenRefresh(timestamp);
}

/* istanbul ignore next */
if (!window.ccsdk) {
  // eslint-disable-next-line no-unused-expressions
  window.ccsdk = {};
}

/* istanbul ignore next */
window.ccsdk.triggerTokenRefresh = () => {
  const timestamp = new Date().toISOString();
  getStore().dispatch({ type: LAST_TOKEN_REFRESH, timestamp });
};
