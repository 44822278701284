import { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Dialog, DialogContent, Header, HeaderTitle, Body, Footer, Box, Button, Alert } from '@ccsdk/kingbolt';
import { withScope, captureException } from '@sentry/browser';
import { selectErrorPopup } from '@customer-connect/redux-selectors';
import { hideErrorAction } from '@customer-connect/redux-actions';
import { useSentrySessionID } from '@ccsdk/core/components/ErrorReporter/hooks';
import { getMessageForType } from '~/components/ErrorPopup/ReportableErrorPopup/utils';
import translations from '~/messages';
import ownMessages from './messages';

const ReportableErrorPopup = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { errorCode, hideError, messages, header, body } = useSelector(selectErrorPopup);
  const sentrySessionID = useSentrySessionID();
  const { sentryInitiated } = window.ccsdkGlobals.sentry || {};

  const title = useMemo(
    () => header || formatMessage(messages?.titleErrorMessage || getMessageForType(errorCode, 'TITLE', messages)),
    [messages, header, errorCode],
  );

  const message = useMemo(
    () => body || formatMessage(messages?.bodyErrorMessage || getMessageForType(errorCode, 'MESSAGE', messages)),
    [messages, body, errorCode],
  );

  const onClose = useCallback(() => {
    dispatch(hideErrorAction());
  }, []);

  useEffect(() => {
    if (sentryInitiated) {
      try {
        withScope((scope) => {
          scope.setTag('portalCrash', true);
          scope.setTag('errorPopup', true);
          scope.setTag('popupTitle', title || 'no title');
          scope.setTag('popupMessage', message || 'no message');
          captureException(new Error(`Error popup triggered: ${title}`));
        });
      } catch (err) {
        console.warn('App Shell', 'Popup', 'Failed to send error data to Sentry', err);
      }
    }
  }, []);

  return (
    <Dialog data-test-error-popup maxWidth="lg" onClose={hideError} open>
      <DialogContent>
        <Header>
          <HeaderTitle data-test-error-popup-title>{title}</HeaderTitle>
        </Header>
        <Body>
          <Box>{message}</Box>
          <Box data-test-error-popup-message>{formatMessage(ownMessages.messageNonInteractive)}</Box>
          <Box>
            <Alert severity="success">{sentrySessionID || 'No replay ID'}</Alert>
          </Box>
        </Body>
        <Footer>
          <Button data-test-error-modal-close-button onClick={onClose}>
            {formatMessage(translations.commonClose)}
          </Button>
        </Footer>
      </DialogContent>
    </Dialog>
  );
};

export default ReportableErrorPopup;
