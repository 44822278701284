import { useCallback, useState, useEffect } from 'react';
import { object } from 'prop-types';
import StackTrace from 'stacktrace-js';
import StackTraceGPS from 'stacktrace-gps';
import { isLocalhost } from '@customer-connect/utils';
import { Icon, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@ccsdk/kingbolt';
import ButtonPopover from '~/components/Debug/DebugPortalWrapper/ButtonPopover';
import { validatedStackframe, createClipboardText } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/StackTraceCell/utils';
import { IconCell } from '~/components/Debug/DebugPortalWrapper/ResultDialog/ResultTable/styles';

/* istanbul ignore next */
const temporaryStackErrorHandler = (error) => {
  console.debug(StackTrace.fromError(error));
};

/* istanbul ignore next: mostly third party logic */
const StackTraceCell = ({ entry }) => {
  const localhost = isLocalhost();
  const [stackInfo, setStackInfo] = useState(null);

  const copyRefToClipboard = useCallback(() => {
    navigator.clipboard.writeText(createClipboardText(stackInfo));
  }, [stackInfo]);

  useEffect(() => {
    entry.stacktrace?.then((stackframes) => {
      const stackframe = stackframes?.slice(localhost ? 3 : 5)[0];

      const gps = new StackTraceGPS();
      gps.pinpoint(stackframe).then((pinpointed) => {
        setStackInfo(validatedStackframe(pinpointed));
      }, temporaryStackErrorHandler);
    });
  }, []);

  return (
    <IconCell>
      {stackInfo ? (
        <ButtonPopover iconType="layers" id={`${entry.id}-stack`}>
          <List>
            <ListItem>
              <ListItemText
                primary={stackInfo.functionName}
                secondary={`${stackInfo.fileName}:${stackInfo.lineNumber}:${stackInfo.columnNumber}`}
                sx={{ paddingRight: '16px' }}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={copyRefToClipboard}>
                  <Icon type="copy" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </ButtonPopover>
      ) : null}
    </IconCell>
  );
};

StackTraceCell.propTypes = {
  entry: object,
};

export default StackTraceCell;
