import { all, call, put, select } from 'redux-saga/effects';
import { loadTranslations, DEFAULT_LOCALE } from '@customer-connect/intl';
import { setAuthStatusAuthorizing, userAuthorized, authorizationErrorOccurred } from '@customer-connect/redux-actions';
import sessionInfoRequest from '@ccsdk/core/utils/request/sessionInfoRequest';
import { selectQueries } from '@customer-connect/redux-selectors';
import { makeSelectConfig } from '@customer-connect/redux-selectors';
import { getBrowserLocale } from '@customer-connect/utils';
import { changeUserSettings } from '@customer-connect/redux-actions';
import { MEASUREMENT_SYSTEM_METRIC } from '@customer-connect/redux-constants';
import getMarketRequest from '@ccsdk/core/utils/request/getMarketRequest';
import { setUser } from '@sentry/browser';
import { fetchUIDRequest } from '../requests/fetchUID';
import { fetchUserRoles } from '~/components/Authentication/requests/fetchRoles';

export const defaultLocaleSelector = makeSelectConfig('defaultLocale');
export const flavourSelector = makeSelectConfig('flavour');

export function* authorize() {
  try {
    console.info('App Shell', 'Authorizing');

    yield put(setAuthStatusAuthorizing());

    const { accessToken, expiresIn, tokenRegistered } = yield call(window.ccauth.renewToken);

    const [sessionInfo, marketInfo, { adobeUID = 'unknown', fleetUID = 'unknown', companyUID = 'unknown' }, { roles }] = yield all([
      call(sessionInfoRequest, accessToken),
      call(getMarketRequest, accessToken),
      call(fetchUIDRequest, accessToken),
      call(fetchUserRoles, accessToken),
    ]);

    window.ccsdkGlobals.adobe.adobeUID = adobeUID;
    window.ccsdkGlobals.adobe.fleetUID = fleetUID;
    window.ccsdkGlobals.adobe.companyUID = companyUID;

    yield call(setUser, { id: adobeUID, username: adobeUID });

    /* istanbul ignore next */
    window.ccSessionInfo = window.Cypress ? { sessionInfo, accessToken, marketInfo } : null;

    const { locale: queryLocale } = yield select(selectQueries);

    const browserLocale = yield call(getBrowserLocale);

    const configLocale = yield select(defaultLocaleSelector);

    const {
      locale: sessionLocale,
      measurement: measurementSystem = MEASUREMENT_SYSTEM_METRIC,
      permissions,
      sessionSettings,
      currentScope,
      ...userInfo
    } = sessionInfo;

    // Handle the rare case where we will get an empty token with no permissions
    if (permissions.length === 0) {
      throw new Error('No permissions found!');
    }

    // Order of precedence: query -> config -> session -> browser -> default
    const locale = queryLocale || configLocale || sessionLocale || browserLocale || DEFAULT_LOCALE;

    // console.debug('App Shell', 'Registering token', 'Loading translations', locale);
    yield call(loadTranslations, locale);

    // console.debug('App Shell', 'Registering token', 'Loading user settings', locale, measurementSystem, sessionSettings);
    yield put(changeUserSettings({ locale, measurementSystem, sessionSettings, marketInfo }));

    // console.debug('App Shell', 'Registering token', 'Authorizing user');
    yield put(
      userAuthorized({
        ...userInfo,
        userUID: adobeUID,
        fleetUID,
        companyUID,
        currentScope,
        accessToken,
        expiresIn,
        permissions,
        roles,
        tokenRegistered,
      }),
    );

    // console.debug('App Shell', 'Registering token', 'Done registering token');
  } catch (error) {
    console.error('App Shell', 'Registering token', 'Caught error during token registration', error);
    yield put(authorizationErrorOccurred(error));
  }
}
